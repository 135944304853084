import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TweenLite } from 'gsap/TweenLite'
import { Power1 } from 'gsap/EasePack'

export default class Camera
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.sizes = _options.sizes
        this.renderer = _options.renderer
        this.css3DRenderer = _options.css3DRenderer
        this.debug = _options.debug
        this.config = _options.config

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.target = new THREE.Vector3(0, 0, 0)
        this.targetEased = new THREE.Vector3(0, 0, 0)
        this.easing = 0.15

        // Debug
        if(this.debug)
        {
            this.debugFolder = this.debug.addFolder('camera')
            // this.debugFolder.open()
        }

        this.setAngle()
        this.setInstance()
        // this.setZoom()
        // this.setPan()
        this.setScrollingControls()
        this.setOrbitControls()
    }

    setAngle()
    {
        // Set up
        this.angle = {}

        // Items
        this.angle.items = {
            // default: new THREE.Vector3(1.135, - 1.45, 1.15),
            // default: new THREE.Vector3(0.145, - 0.750, 2.00),
            default: new THREE.Vector3(-0.157, -0.750, 2.00),
            projects: new THREE.Vector3(0.38, - 1.4, 1.63)
        }

        // Value
        this.angle.value = new THREE.Vector3()
        this.angle.value.copy(this.angle.items.default)

        // Set method
        this.angle.set = (_name) =>
        {
            const angle = this.angle.items[_name]
            if(typeof angle !== 'undefined')
            {
                TweenLite.to(this.angle.value, 2, { ...angle, ease: Power1.easeInOut })
            }
        }

        // Debug
        if(this.debug)
        {
            this.debugFolder.add(this, 'easing').step(0.0001).min(0).max(1).name('easing')
            this.debugFolder.add(this.angle.value, 'x').step(0.001).min(- 2).max(2).name('invertDirectionX').listen()
            this.debugFolder.add(this.angle.value, 'y').step(0.001).min(- 2).max(2).name('invertDirectionY').listen()
            this.debugFolder.add(this.angle.value, 'z').step(0.001).min(- 2).max(5).name('invertDirectionZ').listen()
        }
    }

    setInstance()
    {
        // Set up
        this.instance = new THREE.PerspectiveCamera(40, this.sizes.viewport.width / this.sizes.viewport.height, 1, 80)
        this.instance.up.set(0, 0, 1)
        this.instance.position.copy(this.angle.value)
        this.instance.lookAt(new THREE.Vector3())
        this.container.add(this.instance)

        // Set up zoom (replace setZoom() method)
        this.zoom = {}
        this.zoom.easing = 0.02
        this.zoom.distance = 20
        this.zoom.targetDistance = this.zoom.distance

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.instance.aspect = this.sizes.viewport.width / this.sizes.viewport.height
            this.instance.updateProjectionMatrix()
        })

        // Time tick
        this.time.on('tick', () =>
        {
            if(!this.orbitControls.enabled)
            {
                this.targetEased.x += (this.target.x - this.targetEased.x) * this.easing
                this.targetEased.y += (this.target.y - this.targetEased.y) * this.easing
                this.targetEased.z += (this.target.z - this.targetEased.z) * this.easing

                // Update zoom // TODO move to Application setConfig() method
                this.zoom.distance += (this.zoom.targetDistance - this.zoom.distance) * this.zoom.easing
                // Apply zoom
                this.instance.position.copy(this.targetEased).add(this.angle.value.clone().normalize().multiplyScalar(this.zoom.distance))

                // Look at target
                this.instance.lookAt(this.targetEased)

                // // Apply pan
                // this.instance.position.x += this.pan.value.x
                // this.instance.position.y += this.pan.value.y

                // Apply scrolling
                this.instance.position.y = this.scrolling.targetEased.y

            }
        })
    }

    // setZoom()
    // {
    //     // Set up
    //     this.zoom = {}
    //     this.zoom.easing = 0.1
    //     this.zoom.minDistance = 14
    //     this.zoom.amplitude = 25 // TODO Change to get full view of the scene later
    //     this.zoom.value = this.config.cyberTruck ? 0.3 : 0.5
    //     this.zoom.targetValue = this.zoom.value
    //     this.zoom.distance = this.zoom.minDistance + this.zoom.amplitude * this.zoom.value

    //     // Listen to mousewheel event
    //     document.addEventListener('mousewheel', (_event) =>
    //     {
    //         this.zoom.targetValue += _event.deltaY * 0.001
    //         this.zoom.targetValue = Math.min(Math.max(this.zoom.targetValue, 0), 1)
    //     }, { passive: true })

    //     // Touch
    //     this.zoom.touch = {}
    //     this.zoom.touch.startDistance = 0
    //     this.zoom.touch.startValue = 0

    //     this.renderer.domElement.addEventListener('touchstart', (_event) =>
    //     {
    //         if(_event.touches.length === 2)
    //         {
    //             this.zoom.touch.startDistance = Math.hypot(_event.touches[0].clientX - _event.touches[1].clientX, _event.touches[0].clientX - _event.touches[1].clientX)
    //             this.zoom.touch.startValue = this.zoom.targetValue
    //         }
    //     })

    //     this.renderer.domElement.addEventListener('touchmove', (_event) =>
    //     {
    //         if(_event.touches.length === 2)
    //         {
    //             _event.preventDefault()

    //             const distance = Math.hypot(_event.touches[0].clientX - _event.touches[1].clientX, _event.touches[0].clientX - _event.touches[1].clientX)
    //             const ratio = distance / this.zoom.touch.startDistance

    //             this.zoom.targetValue = this.zoom.touch.startValue - (ratio - 1)
    //             this.zoom.targetValue = Math.min(Math.max(this.zoom.targetValue, 0), 1)
    //         }
    //     })

    //     // Time tick event
    //     this.time.on('tick', () =>
    //     {
    //         this.zoom.value += (this.zoom.targetValue - this.zoom.value) * this.zoom.easing
    //         this.zoom.distance = this.zoom.minDistance + this.zoom.amplitude * this.zoom.value
    //     })
    // }

    // setPan()
    // {
    //     // Set up
    //     this.pan = {}
    //     this.pan.enabled = false
    //     this.pan.active = false
    //     this.pan.easing = 0.1
    //     this.pan.start = {}
    //     this.pan.start.x = 0
    //     this.pan.start.y = 0
    //     this.pan.value = {}
    //     this.pan.value.x = 0
    //     this.pan.value.y = 0
    //     this.pan.targetValue = {}
    //     this.pan.targetValue.x = this.pan.value.x
    //     this.pan.targetValue.y = this.pan.value.y
    //     this.pan.raycaster = new THREE.Raycaster()
    //     this.pan.mouse = new THREE.Vector2()
    //     this.pan.needsUpdate = false
    //     this.pan.hitMesh = new THREE.Mesh(
    //         new THREE.PlaneBufferGeometry(500, 500, 1, 1),
    //         new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true, visible: false })
    //     )
    //     this.container.add(this.pan.hitMesh)

    //     this.pan.reset = () =>
    //     {
    //         this.pan.targetValue.x = 0
    //         this.pan.targetValue.y = 0
    //     }

    //     this.pan.enable = () =>
    //     {
    //         this.pan.enabled = true

    //         // Update cursor
    //         this.renderer.domElement.classList.add('has-cursor-grab')
    //     }

    //     this.pan.disable = () =>
    //     {
    //         this.pan.enabled = false

    //         // Update cursor
    //         this.renderer.domElement.classList.remove('has-cursor-grab')
    //     }

    //     this.pan.down = (_x, _y) =>
    //     {
    //         if(!this.pan.enabled)
    //         {
    //             return
    //         }

    //         // Update cursor
    //         this.renderer.domElement.classList.add('has-cursor-grabbing')

    //         // Activate
    //         this.pan.active = true

    //         // Update mouse position
    //         this.pan.mouse.x = (_x / this.sizes.viewport.width) * 2 - 1
    //         this.pan.mouse.y = - (_y / this.sizes.viewport.height) * 2 + 1

    //         // Get start position
    //         this.pan.raycaster.setFromCamera(this.pan.mouse, this.instance)

    //         const intersects = this.pan.raycaster.intersectObjects([this.pan.hitMesh])

    //         if(intersects.length)
    //         {
    //             this.pan.start.x = intersects[0].point.x
    //             this.pan.start.y = intersects[0].point.y
    //         }
    //     }

    //     this.pan.move = (_x, _y) =>
    //     {
    //         if(!this.pan.enabled)
    //         {
    //             return
    //         }

    //         if(!this.pan.active)
    //         {
    //             return
    //         }

    //         this.pan.mouse.x = (_x / this.sizes.viewport.width) * 2 - 1
    //         this.pan.mouse.y = - (_y / this.sizes.viewport.height) * 2 + 1

    //         this.pan.needsUpdate = true
    //     }

    //     this.pan.up = () =>
    //     {
    //         // Deactivate
    //         this.pan.active = false

    //         // Update cursor
    //         this.renderer.domElement.classList.remove('has-cursor-grabbing')
    //     }

    //     // Mouse
    //     window.addEventListener('mousedown', (_event) =>
    //     {
    //         this.pan.down(_event.clientX, _event.clientY)
    //     })

    //     window.addEventListener('mousemove', (_event) =>
    //     {
    //         this.pan.move(_event.clientX, _event.clientY)
    //     })

    //     window.addEventListener('mouseup', () =>
    //     {
    //         this.pan.up()
    //     })

    //     // Touch
    //     this.renderer.domElement.addEventListener('touchstart', (_event) =>
    //     {
    //         if(_event.touches.length === 1)
    //         {
    //             this.pan.down(_event.touches[0].clientX, _event.touches[0].clientY)
    //         }
    //     })

    //     this.renderer.domElement.addEventListener('touchmove', (_event) =>
    //     {
    //         if(_event.touches.length === 1)
    //         {
    //             this.pan.move(_event.touches[0].clientX, _event.touches[0].clientY)
    //         }
    //     })

    //     this.renderer.domElement.addEventListener('touchend', () =>
    //     {
    //         this.pan.up()
    //     })

    //     // Time tick event
    //     this.time.on('tick', () =>
    //     {
    //         // If active
    //         if(this.pan.active && this.pan.needsUpdate)
    //         {
    //             // Update target value
    //             this.pan.raycaster.setFromCamera(this.pan.mouse, this.instance)

    //             const intersects = this.pan.raycaster.intersectObjects([this.pan.hitMesh])

    //             if(intersects.length)
    //             {
    //                 this.pan.targetValue.x = - (intersects[0].point.x - this.pan.start.x)
    //                 this.pan.targetValue.y = - (intersects[0].point.y - this.pan.start.y)
    //             }

    //             // Update needsUpdate
    //             this.pan.needsUpdate = false
    //         }

    //         // Update value and apply easing
    //         this.pan.value.x += (this.pan.targetValue.x - this.pan.value.x) * this.pan.easing
    //         this.pan.value.y += (this.pan.targetValue.y - this.pan.value.y) * this.pan.easing
    //     })
    // }

    setScrollingControls() {
        // Set up scrolling
        this.scrolling = {};
        this.scrolling.enabled = true;
        this.scrolling.easing = 0.10;
        this.scrolling.start = { x: 0, y: -7 };
        this.scrolling.target = { x: 0, y: -7 }; // This will hold the target position for the camera to look at.
        this.scrolling.targetEased = { x: 0, y: -7 }; // This will hold the target position for the camera to look at.
    
        // Listen to mousewheel event for horizontal panning
        document.addEventListener('mousewheel', (_event) => {
            this.scrolling.target.y -= _event.deltaY * 0.01; // Invert direction if needed
        }, { passive: true });

        // Touch end to adjust zoom
        this.renderer.domElement.addEventListener('touchend', (_event) => {
            this.zoom.targetDistance = this.config.touch ? 30 : 20
        }, { passive: true });
    
        // Touch start for panning
        this.renderer.domElement.addEventListener('touchstart', (_event) => {
            if (_event.touches.length === 1) {
                this.scrolling.start.y = _event.touches[0].pageY;
            }
        });
        this.css3DRenderer.domElement.addEventListener('touchstart', (_event) => {
            if (_event.touches.length === 1) {
                this.scrolling.start.y = _event.touches[0].pageY;
            }
        });
    
        // Touch move for panning
        this.renderer.domElement.addEventListener('touchmove', (_event) => {
            if (_event.touches.length === 1) {
                _event.preventDefault();
                const deltaY = _event.touches[0].pageY - this.scrolling.start.y;
                this.scrolling.target.y += deltaY * 0.01; // Apply negative factor if moving right
                this.scrolling.start.y = _event.touches[0].pageY;
            }
        });
        this.css3DRenderer.domElement.addEventListener('touchmove', (_event) => {
            if (_event.touches.length === 1) {
                _event.preventDefault();
                const deltaY = _event.touches[0].pageY - this.scrolling.start.y;
                this.scrolling.target.y += deltaY * 0.01; // Apply negative factor if moving right
                this.scrolling.start.y = _event.touches[0].pageY;
            }
        });

        this.time.on('tick', () => {
            // Apply easing to the target position for a smooth transition
            this.scrolling.targetEased.y += (this.scrolling.target.y - this.scrolling.targetEased.y) * this.scrolling.easing;
        });

        // Debug
        if(this.debug)
        {
            this.debugFolder.add(this.zoom, 'distance').step(0.001).min(0).max(50).name('zoomDistance').listen()
        }


    }

    setOrbitControls()
    {
        // Set up
        this.orbitControls = new OrbitControls(this.instance, this.renderer.domElement)
        this.orbitControls.enabled = false
        this.orbitControls.enableKeys = false
        this.orbitControls.zoomSpeed = 0.5

        // Debug
        if(this.debug)
        {
            this.debugFolder.add(this.orbitControls, 'enabled').name('orbitControlsEnabled')
        }
    }
}
